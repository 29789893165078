'use client';

import { errorHandler } from '@/config/stackdriver';
import Link from 'next/link';
import { useEffect } from 'react';

// Tipo para las props de error
type ErrorProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function Error({ error, reset }: ErrorProps) {
  
  useEffect(() => {
    console.error(error)
    errorHandler?.report(error)
  }, [])

  // Determinar el código de error (si está disponible)
  const errorCode = error.message.match(/(\d{3})/)?.[0] || '500';
  const statusCode = parseInt(errorCode);
  
  // Mensaje según el código de estado
  let message = 'Disculpa, ocurrió un error cargando esta página.';
  if (statusCode === 404) {
    message = 'Lo sentimos, la página que buscas no existe.';
  } else if (statusCode === 500) {
    message = 'Tuvimos un error interno en el servidor.';
  }

  const timestamp = new Date().toLocaleString();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="w-full max-w-lg px-8 py-12 bg-white rounded-xl shadow-lg">
        {/* Icono de error */}
        <div className="flex justify-center mb-6">
          <div className="p-4 bg-red-100 rounded-full">
            <svg 
              className="w-16 h-16 text-red-500" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
              />
            </svg>
          </div>
        </div>
        
        {/* Mensaje de error */}
        <div className="text-center mb-6">
          <h1 className="text-3xl font-bold text-gray-800 mb-3">{message}</h1>
          <p className="text-gray-600 mb-4">{error.message}</p>
          
          {/* Digest del error (si está disponible) */}
          {error.digest && (
            <div className="mt-4 p-3 bg-gray-100 rounded-lg">
              <p className="text-sm text-gray-500 font-mono">Código de referencia:</p>
              <p className="text-sm text-gray-800 font-mono break-all">{error.digest}</p>
            </div>
          )}
          
          <p className="text-xs text-gray-500 mt-4">{timestamp}</p>
        </div>
        
        {/* Botones de acción */}
        <div className="flex flex-col sm:flex-row justify-center gap-3 mt-6">
          <button
            onClick={reset}
            className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center"
          >
            <svg 
              className="w-4 h-4 mr-2" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth="2" 
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
              />
            </svg>
            Intentar nuevamente
          </button>
          
          <Link href="/" className="w-full sm:w-auto">
            <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 font-medium py-2 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center">
              <svg 
                className="w-4 h-4 mr-2" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="2" 
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" 
                />
              </svg>
              Volver al inicio
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}